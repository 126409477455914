import axios from 'axios';
import {
	PageLayout,
	getThemeFromUrl,
	PostcodeLookup,
	StringHelpers,
	LangContext,
	route,
	API_ENDPOINTS,
	customToast,
	Variants,
	useForm,
	Button,
	Themes,
	SubTitle,
	WidthConstrainedContainer,
	Card,
	CardBody,
	BottomAnchoredControls,
	FormInput,
} from 'carrier-fe';
import { useEffect, useMemo, useState, useContext } from 'react';
import { LangMyCompany } from '../../../types/lang/my-company';

const defaultData = {
	name: '',
	address_line_1: '',
	address_line_2: '',
	address_line_3: '',
	town_city: '',
	state_county: '',
	postcode_zipcode: '',
	country_code_iso_3: '',
	fgas_refcom_standard_number: '',
	refcom_elite_number: '',
	account_number: '',
	dispatch_note_field_label: '',
	dispatch_note_required: '',
	distributor_claim_type: '',
	distributor_claim_inbox_email: '',
	gas_boiler_systems_enabled: false,
	heat_pumps_systems_enabled: false,
	solar_systems_enabled: false,
	other_systems_enabled: false,
	gas_safe_number: '',
	gas_safe_confirmed: true,
	type: '',
};

type Data = typeof defaultData;

type Page<T> = {
	page: {
		fields: T;
		submit_button: {
			default: 'Update';
			submitting: 'Updating...';
		};
		title: 'My Company';
		sections: {
			details: 'Details';
			additional: 'Additional';
			installer: 'Installer';
			distributor: 'Distributor';
		};
		download_company_warranty_certificate: 'Download Company Warranty Certificate';
	};
};

function MyCompany() {
	const [isFetching, setIsFetching] = useState(false);
	const { getLangPage } = useContext(LangContext);
	const [lang, setLang] = useState<Page<LangMyCompany>>();
	const theme = useMemo(() => getThemeFromUrl(), []);
	const [claimType, setClaimType] = useState([]);

	const read = async () => {
		if (isFetching) return;
		setIsFetching(true);

		try {
			const res = await axios.get<{ data: Data }>(
				route(API_ENDPOINTS.GENERIC.MY_COMPANY.EDIT)
			);

			if (!res || !res.data) return;
			setValues(res.data.data);
		} catch (error: any) {
			if (!!error && !!error.response && !!error.response.data) {
				if (!!error.response.data.message) {
					customToast({
						title: error.response.data.message,
						variant: Variants.Danger,
					});
				}
			} else {
				console.log(error);
				customToast({
					title: 'An error occurred. Please refresh the page to try again.',
					variant: Variants.Danger,
				});
			}
		} finally {
			setIsFetching(false);
		}
	};

	const update = (payload: typeof defaultData) => {
		const { dispatch_note_required, ...rest } = payload;

		const updatedPayload =
			dispatch_note_required === 'yes'
				? { ...rest, dispatch_note_required: true }
				: { ...rest, dispatch_note_required: false };

		return axios.patch<{ data: Data; message: string }>(
			route(API_ENDPOINTS.GENERIC.MY_COMPANY.UPDATE),
			updatedPayload
		);
	};

	const { Input, Checkbox, Submit, errors, store, setValues } = useForm(
		update,
		defaultData
	);

	const viewable = (props: {
		themes?: typeof theme | (typeof theme)[];
		type?: 'distributor' | 'installer';
	}) => {
		const { themes, type } = props;

		if (themes && ![themes].flat().includes(theme)) {
			return false;
		}

		if (type && store.type !== type) {
			return false;
		}

		return true;
	};

	const toshibaInstaller = viewable({
		themes: Themes.Toshiba,
		type: 'installer',
	});

	const toshibaDistributor = viewable({
		themes: Themes.Toshiba,
		type: 'distributor',
	});

	const vokeraViessmann = viewable({
		themes: [Themes.Vokera, Themes.Viessmann],
	});

	useEffect(() => {
		read();
		getLangPage('dashboard/my_company').then((res: Page<LangMyCompany>) =>
			setLang(res)
		);
	}, []);

	useEffect(() => {
		if (toshibaDistributor) {
			axios
				.get(
					route(API_ENDPOINTS.GENERIC.SELECT.DISTRIBUTOR_CLAIM_TYPES)
				)
				.then((res) => setClaimType(res.data.data))
				.catch((err) => {
					console.error(err);
				});
		}
	}, [toshibaDistributor]);

	return (
		<PageLayout
			title={StringHelpers.title(lang?.page.title || 'My Company')}
		>
			<WidthConstrainedContainer>
				<Card>
					<CardBody className={'pb-0'}>
						<SubTitle
							title={StringHelpers.title(
								lang?.page.sections.details || 'Details'
							)}
							className="mb-3 w-100"
							style={{ color: '#464C5E' }}
						/>
						{Input({
							name: 'name',
							label: lang?.page.fields.name || 'Name',
						})}
						{toshibaInstaller && (
							<div
								className={
									'd-flex justify-content-end align-items-center'
								}
							>
								<Button
									label={
										lang?.page
											.download_company_warranty_certificate ||
										'Download Company Warranty Certificate'
									}
									variant={Variants.Info}
								/>
							</div>
						)}
					</CardBody>
				</Card>
				<Card>
					<CardBody className={'pb-0'}>
						<PostcodeLookup
							data={store}
							setData={setValues}
							errors={errors}
						/>
					</CardBody>
				</Card>
				{toshibaInstaller && (
					<Card>
						<CardBody className={'pb-0'}>
							<SubTitle
								title={StringHelpers.title(
									lang?.page.sections.installer || 'Installer'
								)}
								className="mb-3 w-100"
								style={{ color: '#464C5E' }}
							/>
							{Input({
								name: 'fgas_refcom_standard_number',
								label:
									lang?.page.fields
										.fgas_refcom_standard_number ||
									'FGAS / REFCOM standard number',
								visible: toshibaInstaller,
							})}
							{Input({
								name: 'refcom_elite_number',
								label:
									lang?.page.fields.refcom_elite_number ||
									'REFCOM elite number',
								visible: toshibaInstaller,
							})}
							{Input({
								name: 'account_number',
								label:
									lang?.page.fields.account_number ||
									'Account number',
								visible: toshibaInstaller,
							})}
						</CardBody>
					</Card>
				)}
				{toshibaDistributor && (
					<Card>
						<CardBody className={'pb-0'}>
							<SubTitle
								title={StringHelpers.title(
									lang?.page.sections.distributor ||
										'Distributor'
								)}
								className="mb-3 w-100"
								style={{ color: '#464C5E' }}
							/>
							{Input({
								name: 'dispatch_note_field_label',
								label:
									lang?.page.fields
										.dispatch_note_field_label ||
									'Dispatch note field label',
								type: 'textarea',
								visible: toshibaDistributor,
							})}
							{Input({
								name: 'dispatch_note_required',
								label:
									lang?.page.fields.dispatch_note_required ||
									'Dispatch note required',
								type: 'select',
								options: [
									{
										label: 'Yes',
										value: 'yes',
									},
									{
										label: 'No',
										value: 'no',
									},
								],
								visible: toshibaDistributor,
							})}
							{Input({
								type: 'select',
								name: 'distributor_claim_type',
								label:
									lang?.page.fields.distributor_claim_type ||
									'Distributor claim type',
								visible: toshibaDistributor,
								options: claimType,
							})}
							{Input({
								name: 'distributor_claim_inbox_email',
								label:
									lang?.page.fields
										.distributor_claim_inbox_email ||
									'Distributor claims inbox email',
								visible: toshibaDistributor,
							})}
						</CardBody>
					</Card>
				)}
				{vokeraViessmann && (
					<Card>
						<CardBody className={'pb-0'}>
							<SubTitle
								title={StringHelpers.title(
									lang?.page.sections.installer || 'Installer'
								)}
								className="mb-3 w-100"
								style={{ color: '#464C5E' }}
							/>
							<div className="d-flex">
								<div className="mx-2">
									<input
										type="checkbox"
										className="btn-check"
										id="gas_boiler_systems_enabled"
										checked={
											store.gas_boiler_systems_enabled as boolean
										}
										onChange={(e) =>
											setValues({
												gas_boiler_systems_enabled:
													e.target.checked,
											})
										}
									/>
									<label
										className="btn btn-secondary mb-4 justify-content-center rounded-3"
										style={{
											width: '120px',
											height: '120px',
											fontSize: '14px',
										}}
										htmlFor="gas_boiler_systems_enabled"
									>
										{lang?.page.fields
											.gas_boiler_systems_enabled ||
											'Gas boiler systems'}
									</label>
								</div>

								<div className="mx-2">
									<input
										type="checkbox"
										className="btn-check"
										id="heat_pumps_systems_enabled"
										checked={
											store.heat_pumps_systems_enabled as boolean
										}
										onChange={(e) =>
											setValues({
												heat_pumps_systems_enabled:
													e.target.checked,
											})
										}
									/>
									<label
										className="btn btn-secondary mb-4 justify-content-center rounded-3"
										style={{
											width: '120px',
											height: '120px',
											fontSize: '14px',
										}}
										htmlFor="heat_pumps_systems_enabled"
									>
										{lang?.page.fields
											.heat_pumps_systems_enabled ||
											'Heat Pump systems'}
									</label>
								</div>

								<div className="mx-2">
									<input
										type="checkbox"
										className="btn-check"
										id="solar_systems_enabled"
										checked={
											store.solar_systems_enabled as boolean
										}
										onChange={(e) =>
											setValues({
												solar_systems_enabled:
													e.target.checked,
											})
										}
									/>
									<label
										className="btn btn-secondary mb-4 justify-content-center rounded-3"
										style={{
											width: '120px',
											height: '120px',
											fontSize: '14px',
										}}
										htmlFor="solar_systems_enabled"
									>
										{lang?.page.fields
											.solar_systems_enabled ||
											'Solar systems'}
									</label>
								</div>

								<div className="mx-2">
									<input
										type="checkbox"
										className="btn-check"
										id="other_systems_enabled"
										checked={
											store.other_systems_enabled as boolean
										}
										onChange={(e) =>
											setValues({
												other_systems_enabled:
													e.target.checked,
											})
										}
									/>
									<label
										className="btn btn-secondary mb-4 justify-content-center rounded-3"
										style={{
											width: '120px',
											height: '120px',
											fontSize: '14px',
										}}
										htmlFor="other_systems_enabled"
									>
										{lang?.page.fields
											.other_systems_enabled ||
											'Other systems'}
									</label>
								</div>
							</div>
							{vokeraViessmann && (
								<FormInput
									type="text"
									name="gas_safe_number"
									label={
										lang?.page.fields.gas_safe_number ||
										'Gas Safe number'
									}
									value={store.gas_safe_number}
									onChange={(value) =>
										setValues({
											gas_safe_confirmed: false,
											gas_safe_number: value as string,
										})
									}
								/>
							)}
							{Checkbox({
								name: 'gas_safe_confirmed',
								label:
									lang?.page.fields.gas_safe_confirmation ||
									'Gas Safe confirmation',
								visible: vokeraViessmann,
							})}
						</CardBody>
					</Card>
				)}
			</WidthConstrainedContainer>
			<BottomAnchoredControls>
				<Submit
					defaultLabel={lang?.page.submit_button.default || 'Upload'}
					loadingLabel={
						lang?.page.submit_button.submitting || 'Uploading'
					}
				/>
			</BottomAnchoredControls>
		</PageLayout>
	);
}

export default MyCompany;
